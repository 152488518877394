import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from './session-storage.service';
import { map, catchError } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService
  ) {}

  addHeaderAuthorization(options: any) {
    options.headers = options.headers.append(
      'Authorization',
      'Bearer ' + this.sessionStorage.getAccessToken()
    );
    return options;
  }

  generateOptions() {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withCredentials: false,
    };
    return OPTIONS;
  }

  generateOptionsUpload(data: any) {
    const options = {
      body: data,
      headers: new HttpHeaders({}),
      reportProgress: true,
    };
    return options;
  }

  get(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (!anonymous) {
      this.addHeaderAuthorization(OPTIONS);
    }
    console.log('GET : ' + url);
    return this.httpClient.get(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  post(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (!anonymous) {
      this.addHeaderAuthorization(OPTIONS);
    }
    console.log('POST : ' + url);
    return this.httpClient.post(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  put(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (!anonymous) {
      this.addHeaderAuthorization(OPTIONS);
    }
    console.log('PUT : ' + url);
    return this.httpClient.put(url, data, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  delete(url: string, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptions();
    if (!anonymous) {
      this.addHeaderAuthorization(OPTIONS);
    }
    console.log('DELETE : ' + url);
    return this.httpClient.delete(url, OPTIONS).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  upload(url: string, data: any, anonymous: boolean): Observable<any> {
    const options = this.generateOptionsUpload(data);
    if (!anonymous) {
      this.addHeaderAuthorization(options);
    }
    console.log('UPLOAD : ' + url);
    return this.httpClient.request('POST', url, options).pipe(
      map((response: any) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        } else {
          throw response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  generateOptionsBlob() {
    const OPTIONS: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
      observe: 'response',
    };
    return OPTIONS;
  }

  getFileBlob(url: string, data: any, anonymous: boolean): Observable<any> {
    const OPTIONS = this.generateOptionsBlob();
    if (!anonymous) {
      this.addHeaderAuthorization(OPTIONS);
    }
    console.log('POST : ' + url);
    return this.httpClient.post(url, data, OPTIONS).pipe(
      map((response: any) => {
        // console.log(response);
        if (response.status === 200) {
          // console.log(response.headers.get('content-disposition'));
          // return { blob: response.body, headers: response.headers };
          return response.body;
        } else {
          return null;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }
}
