import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private sessionStorageService: SessionStorageService) {}

  getAccessToken(): boolean {
    const TOKEN = this.sessionStorageService.getAccessToken();
    if (TOKEN) {
      return true;
    } else {
      return false;
    }
  }
}
