<div class="grid flex align-items-center">
    <div class="col-12 md:col-9 py-0">
        <div class="grid">
            <div class="col-12 md:col-4">
                <span>นำเข้าข้อมูล : {{groupDataPersonDetail?.transactionTypeName}}</span>
                <span class="font-bold"></span>
            </div>
            <div class="col-12 md:col-4">
                <span>ประเภทหน่วยงาน : {{groupDataPersonDetail?.orgTypeName}}</span>
                <span class="font-bold"></span>
            </div>
            <div class="col-12 md:col-4">
                <span>ชื่อ-นามสกุล : {{groupDataPersonDetail?.fullName}}</span>
                <span class="font-bold"></span>
            </div>

            <div class="col-12 md:col-4">
                <span>ประเภทนายทหาร : {{groupDataPersonDetail?.personnelTypeName}}</span>
                <span class="font-bold"></span>
            </div>
            <div class="col-12 md:col-4">
                <span>หน่วยงาน : {{groupDataPersonDetail?.currentOrgUnitName1}}</span>
                <span class="font-bold"></span>
            </div>
            <div class="col-12 md:col-4">
                <span>เลขประจำตัวประชาชน : {{groupDataPersonDetail?.citizenId}}</span>
                <span class="font-bold"></span>
            </div>

            <div class="col-12 md:col-4">
                <span>ประเภทบุคคล : {{groupDataPersonDetail?.personnelGroupTypeName}}</span>
                <span class="font-bold"></span>
            </div>
            <div class="col-12 md:col-4" *ngIf="menuType != 'การออกจากราชการ'">
                <span>ตำแหน่ง : {{groupDataPersonDetail?.positionName}}</span>
                <span class="font-bold"></span>
            </div>
            <div class="col-12 md:col-4">
                <span>เลขประจำตัวทหาร : {{groupDataPersonDetail?.militaryId}}</span>
                <span class="font-bold"></span>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-3 flex justify-content-center py-0">
        <ng-container *ngIf="!thumbnail">
            <p-skeleton size="7rem"></p-skeleton>
        </ng-container>
        <ng-container *ngIf="thumbnail">
            <img [src]="thumbnail" class="w-7rem h-7rem border-round-md">
        </ng-container>
    </div>
</div>