import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-step-bar',
    templateUrl: './step-bar.component.html',
    styleUrls: ['./step-bar.component.scss']
})
export class StepBarComponent {
    menu: string = ''
    @Input() activeStepIndex: number = 0;
    @Input() isOrdersModal: boolean = true;
    @Output() emitActiveStepIndex = new EventEmitter<any>();

    statusActiveStep1 : boolean = false
    statusActiveStep2 : boolean = false
    statusActiveStep3 : boolean = false
    statusActiveStep4 : boolean = false
    statusActiveStep5 : boolean = false
    
    ngOnInit() {
        this.setTitle()
    }

    setTitle(){
        if(this.activeStepIndex == 1){
            this.menu = 'นำเข้าข้อมูล'
        }else if(this.activeStepIndex == 2){
            this.menu = 'ตรวจสอบคุณสมบัติ'
        }else if(this.activeStepIndex == 3){
            this.menu = 'ยกร่างคำสั่ง'
        }else if(this.activeStepIndex == 4){
            this.menu = 'อนุมัติคำสั่ง'
        }else if(this.activeStepIndex == 5){
            this.menu = 'สำเนาเเจกจ่าย'
        }
    }

    onActiveStepsChange(activeStepIndexClick: any) {
        // if(activeStepIndexClick == 2){
        //     if(this.statusActiveStep1 != true){
        //         return ;
        //     }
        // }else if(activeStepIndexClick == 3){
        //     if(this.statusActiveStep2 != true){
        //         return ;
        //     }
        // }else if(activeStepIndexClick == 4){
        //     if(this.statusActiveStep3 != true){
        //         return ;
        //     }
        // }else if(activeStepIndexClick == 5){
        //     if(this.statusActiveStep4 != true){
        //         return ;
        //     }
        // }
        this.activeStepIndex = activeStepIndexClick
        this.emitActiveStepIndex.emit(this.activeStepIndex)
        this.setTitle()
    }

    handleCircle(activeStepIndexClick: number, defaultIndex: number) {
        return activeStepIndexClick >= defaultIndex 
            ? 'bg-purple-900 border-round-xl' 
            : 'bg-gray-300 border-round-xl';
    }

    handleTextInCircle(activeStepIndexClick: any, defaultIndex: number){
        return activeStepIndexClick >= defaultIndex 
            ? 'text-white' 
            : 'text-gray-600'
    }

    handleLine(activeStepIndexClick: number, defaultIndex: number) {
        return activeStepIndexClick >= defaultIndex 
            ? 'bg-purple-900' 
            : 'bg-gray-300';
    }

    handleTextLabel(activeStepIndexClick: number, defaultIndex: number) {
        return activeStepIndexClick >= defaultIndex 
            ? 'text-purple-900' 
            : 'text-gray-600';
    }

    getStatus(){
        if(
            this.statusActiveStep1 == true &&
            this.statusActiveStep2 == true &&
            this.statusActiveStep3 == true &&
            this.statusActiveStep4 == true &&
            this.statusActiveStep5 == true 
        ){
            return 'สำเร็จ'
        }else{
            return 'อยู่ระหว่างดำเนินการ'
        }
        
    }
}
