<div class="layout-topbar">
  <div class="topbar-left">
    <a #menubutton tabindex="0" class="menu-button p-trigger" (click)="onMenuButtonClick()">
      <i class="pi pi-chevron-left"></i>
    </a>
    <span class="topbar-separator"></span>
    <span class="font-bold">ระบบคลังข้อมูลด้านกำลังพลฯ</span>
  </div>

  <div class="layout-topbar-menu-section">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-mask modal-in"></div>

  <div class="topbar-right">
    <ul class="topbar-menu">
      <li class="profile-item static sm:relative border-none">
        <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
          leaveActiveClass="fadeout" [hideOnOutsideClick]="true" class="px-2 py-0">
          <i class="fa-solid fa-circle-user mr-2"></i>
          <span class="profile-name">{{fullName}}</span>
        </a>
        <ul
          class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-20rem mt-2 right-0 z-5 top-auto">
          <li>
            <a pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
              (click)="logout()">
              <i class="fa-solid fa-power-off mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">ออกจากระบบ</span>
              </span>
            </a>
          </li>
        </ul>
    </ul>
  </div>
</div>
