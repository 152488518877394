export const environment = {
  apiMstUrl: 'https://modhrmisapi.kbtdevops.com/mst/api/',
  apiPerUrl: 'https://modhrmisapi.kbtdevops.com/per/api/',
  apiAuthUrl: 'https://modhrmisapi.kbtdevops.com/auth/api/',
  apiUsrUrl: 'https://modhrmisapi.kbtdevops.com/usr/api/',
  apiAppUrl: 'https://modhrmisapi.kbtdevops.com/app/api/',
  apiAsmUrl: 'https://modhrmisapi.kbtdevops.com/asm/api/',
  apiTransUrl: 'https://modhrmisapi.kbtdevops.com/trans/api/',
  apiTempUrl: 'https://modhrmisapi.kbtdevops.com/temp/api/',
  apiDiscUrl: 'https://modhrmisapi.kbtdevops.com/ret/api/',
  apiObjStorageUrl: 'https://modhrmisapi.kbtdevops.com/object-storage/api/',
  apiPdfUrl: 'https://modhrmisapi.kbtdevops.com/report/ModReportService/',
  apiOrdUrl: 'https://modhrmisapi.kbtdevops.com/ord/api/',
  apiImpUrl: 'https://modhrmisapi.kbtdevops.com/imp/api/',
  appVersion: '1.0.0',
  appBuildNo: '2024101404',
  rows: 50,
  rowsPerPageOptions: [20, 50, 100],
  thaiDApi: "https://imauth.bora.dopa.go.th/api/",
  thaiDToken: "TldaT1kxUktPVVZwTnpaVVptbEJWRFJ5VEd4NWVUTkpibGt3U0VaQ2VXZzpVbTlKUzA4MFNUVnFkR3RsWm5acVEzbFFaVWR6V1hkblMyOUZkMDFpTW5wWVNuQlpNbk40WlE=",
  thaiDClientId: "NWZOY1RKOUVpNzZUZmlBVDRyTGx5eTNJblkwSEZCeWg",
  thaiDClientSecret: "Um9JS080STVqdGtlZnZqQ3lQZUdzWXdnS29Fd01iMnpYSnBZMnN4ZQ",
  thaiDRedirectUri: "https://modhrmis.kbtdevops.com/thaidcallback",
  thaiDScope: "pid name birthdate given_name family_name address gender date_of_issuance date_of_expiry",
};
