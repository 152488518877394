<nav class="layout-breadcrumb">
  <ol class="mb-3">
    <li *ngFor="let item of breadcrumbs$ | async; let i = index; let last = last"
      class="text-purple-900 font-normal text-base">
      <i *ngIf="i === 0" class="fa-light fa-house px-3"></i>
      <a *ngIf="item.url; else noUrl" [routerLink]="item.url" class="pointer-events-none pr-3 text-purple-900">{{
        item.label }}</a>
      <ng-template #noUrl>{{ item.label }}</ng-template>
      <ng-container *ngIf="!last" class="layout-breadcrumb-chevron "> / </ng-container>
    </li>
  </ol>
</nav>