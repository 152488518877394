import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { Router } from '@angular/router';
import { SessionStorageService } from '../core/services/session-storage.service';
import { AuthService } from '../services/api/auth.service';
import { HttpErrorHandlerService } from '../core/services/http-error-handler.service';
import { UtilsService } from '../core/services/utils.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { AppMenuComponent } from './app.menu.component';
import { PersonalInfoService } from '../services/api/per/personal-info.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent {
  userProfile: any = null;
  fullName: string = '';

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private utils: UtilsService,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private authService: AuthService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private localStorageService: LocalStorageService,
    private personalInfoService: PersonalInfoService
  ) {}

  ngOnInit(): void {
    this.userProfile = this.sessionStorage.getUserProfile();
    this.fullName = this.userProfile?.name
      ? this.userProfile?.name
      : this.userProfile?.userName;
  }

  anchor() {
    this.appSidebar.anchor();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  onRightMenuClick() {
    this.layoutService.showRightMenu();
  }

  get logo() {
    const logo =
      this.layoutService.config.menuTheme === 'white' ||
      this.layoutService.config.menuTheme === 'orange'
        ? 'dark'
        : 'white';
    return logo;
  }

  goToProfile() {
    let userPofile = this.sessionStorage.getUserProfile();
    this.localStorageService.setItem(
      'personal_id',
      userPofile?.personalInfoId ? userPofile?.personalInfoId : null
    );
    this.localStorageService.setItem(
      'govemployeeinfo_id',
      userPofile?.govEmployeeInfoId ? userPofile?.govEmployeeInfoId : null
    );
    this.localStorageService.setItem(
      'employeeinfo_id',
      userPofile?.employeeInfoId ? userPofile?.employeeInfoId : null
    );
    this.localStorageService.setItem(
      'employeetempinfo_id',
      userPofile?.employeeTempInfoId ? userPofile?.employeeTempInfoId : null
    );

    this.personalInfoService.selectPersonalId.next(userPofile?.personalInfoId);
    this.router.navigateByUrl('/per');
  }

  logout() {
    let hash = this.sessionStorage?.getHash();
    let user = this.sessionStorage.getUserProfile();
    let params = {
      hash: hash ? hash : '',
      userKey: user?.userKey,
    };
    this.utils.spinnerShow();
    this.authService.logout(params).subscribe({
      next: (resp: any) => {
        if (resp && resp?.status && resp?.status == 200) {
          this.sessionStorage.clearStorage();
          this.router.navigateByUrl('/login');
        }
      },
      error: (err: any) => {
        this.utils.spinnerHide();
        this.httpErrorHandlerService.handleError(err);
      },
      complete: () => {
        this.utils.spinnerHide();
      },
    });
  }
}
