<div class="grid py-0">
    <div class="col-12 xl:col-2 py-0">
        <span class="border-round-lg text-2xl font-bold text-purple-900 p-3">{{menu}}</span>
    </div>
    <div class="col-12 xl:col-8 py-0">
        <ul class="list-none flex flex-column gap-3 lg:flex-row justify-content-between p-0">

            <ng-container *ngIf="isOrdersModal">
                <li class="flex flex-column align-items-center gap-3 relative w-full">
                    <div class="w-13rem py-3 cursor-pointer transition-colors transition-duration-100" [ngClass]="activeStepIndex == 1 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(1)">
                        <div class="flex justify-content-center">
                            <div pRipple class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1"
                            [ngClass]="statusActiveStep1 ? 'bg-green-500' : handleCircle(activeStepIndex, 1)">
                                <span class="text-2xl" [ngClass]="statusActiveStep1 ? 'text-white' : handleTextInCircle(activeStepIndex, 1)">
                                    <ng-container *ngIf="!statusActiveStep1">1</ng-container>
                                    <ng-container *ngIf="statusActiveStep1"><i class="fa-light fa-check"></i></ng-container>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-column align-items-center">
                            <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep1 ? 'text-green-500' : handleTextLabel(activeStepIndex, 1)">นำเข้าข้อมูล</h1>
                        </div>
                    </div>
                </li>
        
                <li class="flex align-items-center flex-column gap-3 relative w-full">
                    <div class="w-13rem py-3 cursor-pointer transition-colors transition-duration-100" [ngClass]="activeStepIndex == 2 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(2)">
                        <div class="flex justify-content-center" >
                            <div pRipple class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1"
                            [ngClass]="statusActiveStep2 ? 'bg-green-500' : handleCircle(activeStepIndex, 2)">
                                <span class="text-2xl" [ngClass]="statusActiveStep2 ? 'text-white' : handleTextInCircle(activeStepIndex, 2)">
                                    <ng-container *ngIf="!statusActiveStep2">2</ng-container>
                                    <ng-container *ngIf="statusActiveStep2"><i class="fa-light fa-check"></i></ng-container>
                                </span>
                            </div>
                        </div>
                        <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep2 ? 'bg-green-500' : handleLine(activeStepIndex, 2)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
                        <div class="flex flex-column align-items-center">
                            <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep2 ? 'text-green-500' : handleTextLabel(activeStepIndex, 2)">ตรวจสอบคุณสมบัติ</h1>
                        </div>
                    </div>
                </li>
            </ng-container>
        
            <li class="flex align-items-center flex-column gap-3 relative w-full">
                <div class="w-13rem py-3 cursor-pointer transition-colors transition-duration-100" [ngClass]="activeStepIndex == 3 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(3)">
                    <div class="flex justify-content-center">
                        <div pRipple class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1 "
                            [ngClass]="statusActiveStep3 ? 'bg-green-500' : handleCircle(activeStepIndex, 3)">
                            <span class="text-2xl" [ngClass]="statusActiveStep3 ? 'text-white' : handleTextInCircle(activeStepIndex, 3)">
                                <ng-container *ngIf="!statusActiveStep3">3</ng-container>
                                <ng-container *ngIf="statusActiveStep3"><i class="fa-light fa-check"></i></ng-container>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="isOrdersModal" class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep3 ? 'bg-green-500' : handleLine(activeStepIndex, 3)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
                    <div class="flex flex-column align-items-center">
                        <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep3 ? 'text-green-500' : handleTextLabel(activeStepIndex, 3)">ยกร่างคำสั่ง</h1>
                    </div>
                </div>
            </li>
    
            <li class="flex align-items-center flex-column gap-3 relative w-full">
                <div class="w-13rem py-3 cursor-pointer transition-colors transition-duration-100" [ngClass]="activeStepIndex == 4 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(4)">
                    <div class="flex justify-content-center">
                        <div pRipple class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1 "
                            [ngClass]="statusActiveStep4 ? 'bg-green-500' : handleCircle(activeStepIndex, 4)">
                            <span class="text-2xl" [ngClass]="statusActiveStep4 ? 'text-white' : handleTextInCircle(activeStepIndex, 4)">
                                <ng-container *ngIf="!statusActiveStep4">4</ng-container>
                                <ng-container *ngIf="statusActiveStep4"><i class="fa-light fa-check"></i></ng-container>
                            </span>
                        </div>
                    </div>
                    <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep4 ? 'bg-green-500' : handleLine(activeStepIndex, 4)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
                    <div class="flex flex-column align-items-center">
                        <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep4 ? 'text-green-500' : handleTextLabel(activeStepIndex, 4)">อนุมัติคำสั่ง</h1>
                    </div>
                </div>
            </li>
    
            <li class="flex align-items-center flex-column gap-3 relative w-full">
                <div class="w-13rem py-3 cursor-pointer transition-colors transition-duration-100" [ngClass]="activeStepIndex == 5 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(5)">
                    <div class="flex justify-content-center ">
                        <div pRipple class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1 "
                            [ngClass]="statusActiveStep5 ? 'bg-green-500' : handleCircle(activeStepIndex, 5)">
                            <span class="text-2xl" [ngClass]="statusActiveStep5 ? 'text-white' : handleTextInCircle(activeStepIndex, 5)">
                                <ng-container *ngIf="!statusActiveStep5">5</ng-container>
                                <ng-container *ngIf="statusActiveStep5"><i class="fa-light fa-check"></i></ng-container>
                            </span>
                        </div>
                    </div>
                    <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep5 ? 'bg-green-500' : handleLine(activeStepIndex, 5)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
                    <div class="flex flex-column align-items-center">
                        <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep5 ? 'text-green-500' : handleTextLabel(activeStepIndex, 5)">สำเนาเเจกจ่าย</h1>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-12 xl:col-2 py-0 flex justify-content-end align-items-start">
        <span class="bg-purple-50 border-round-lg text-xl font-bold text-purple-900 p-3">{{getStatus()}}</span>
    </div>
</div>

<!-- <div class="flex justify-content-between">
    <span class="border-round-lg text-2xl font-bold text-purple-900 p-3">{{menu}}</span>
    <span class="bg-purple-50 border-round-lg text-xl font-bold text-purple-900 p-3">อยู่ระหว่าง{{menu}}</span>
</div>

<ul class="list-none flex flex-column gap-3 lg:flex-row justify-content-between p-0">

    <li class="flex flex-column align-items-center gap-3 relative w-full">
        <div class="w-13rem py-3 cursor-pointer" [ngClass]="activeStepIndex == 1 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(1)">
            <div class="flex justify-content-center">
                <div class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1"
                [ngClass]="statusActiveStep1 ? 'bg-green-500' : handleCircle(activeStepIndex, 1)">
                    <span class="text-2xl" [ngClass]="statusActiveStep1 ? 'text-white' : handleTextInCircle(activeStepIndex, 1)">
                        <ng-container *ngIf="!statusActiveStep1">1</ng-container>
                        <ng-container *ngIf="statusActiveStep1"><i class="fa-light fa-check"></i></ng-container>
                    </span>
                </div>
            </div>
            <div class="flex flex-column align-items-center">
                <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep1 ? 'text-purple-900' : handleTextLabel(activeStepIndex, 1)">นำเข้าข้อมูล</h1>
            </div>
        </div>
    </li>

    <li class="flex align-items-center flex-column gap-3 relative w-full ">
        <div class="w-13rem py-3 cursor-pointer" [ngClass]="activeStepIndex == 2 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(2)">
            <div class="flex justify-content-center" >
                <div class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1"
                [ngClass]="statusActiveStep2 ? 'bg-green-500' : handleCircle(activeStepIndex, 2)">
                    <span class="text-2xl" [ngClass]="statusActiveStep2 ? 'text-white' : handleTextInCircle(activeStepIndex, 2)">
                        <ng-container *ngIf="!statusActiveStep2">2</ng-container>
                        <ng-container *ngIf="statusActiveStep2"><i class="fa-light fa-check"></i></ng-container>
                    </span>
                </div>
            </div>
            <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep2 ? 'bg-purple-900' : handleLine(activeStepIndex, 2)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
            <div class="flex flex-column align-items-center">
                <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep2 ? 'text-purple-900' : handleTextLabel(activeStepIndex, 2)">ตรวจสอบคุณสมบัติ</h1>
            </div>
        </div>
    </li>

    <li class="flex align-items-center flex-column gap-3 relative w-full">
        <div class="w-13rem py-3 cursor-pointer" [ngClass]="activeStepIndex == 3 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(3)">
            <div class="flex justify-content-center">
                <div class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1 "
                    [ngClass]="statusActiveStep3 ? 'bg-green-500' : handleCircle(activeStepIndex, 3)">
                    <span class="text-2xl" [ngClass]="statusActiveStep3 ? 'text-white' : handleTextInCircle(activeStepIndex, 3)">
                        <ng-container *ngIf="!statusActiveStep3">3</ng-container>
                        <ng-container *ngIf="statusActiveStep3"><i class="fa-light fa-check"></i></ng-container>
                    </span>
                </div>
            </div>
            <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep3 ? 'bg-purple-900' : handleLine(activeStepIndex, 3)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
            <div class="flex flex-column align-items-center">
                <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep3 ? 'text-purple-900' : handleTextLabel(activeStepIndex, 3)">ยกร่างคำสั่ง</h1>
            </div>
        </div>
    </li>

    <li class="flex align-items-center flex-column gap-3 relative w-full">
        <div class="w-13rem py-3 cursor-pointer" [ngClass]="activeStepIndex == 4 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(4)">
            <div class="flex justify-content-center">
                <div class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1 "
                    [ngClass]="statusActiveStep4 ? 'bg-green-500' : handleCircle(activeStepIndex, 4)">
                    <span class="text-2xl" [ngClass]="statusActiveStep4 ? 'text-white' : handleTextInCircle(activeStepIndex, 4)">
                        <ng-container *ngIf="!statusActiveStep4">4</ng-container>
                        <ng-container *ngIf="statusActiveStep4"><i class="fa-light fa-check"></i></ng-container>
                    </span>
                </div>
            </div>
            <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep4 ? 'bg-purple-900' : handleLine(activeStepIndex, 4)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
            <div class="flex flex-column align-items-center">
                <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep4 ? 'text-purple-900' : handleTextLabel(activeStepIndex, 4)">อนุมัติคำสั่ง</h1>
            </div>
        </div>
    </li>

    <li class="flex align-items-center flex-column gap-3 relative w-full">
        <div class="w-13rem py-3 cursor-pointer" [ngClass]="activeStepIndex == 5 ? 'bg-gray-200 border-round-xl' : ''" (click)="onActiveStepsChange(5)">
            <div class="flex justify-content-center ">
                <div class="flex justify-content-center align-items-center border-circle w-4rem h-4rem z-1 "
                    [ngClass]="statusActiveStep5 ? 'bg-green-500' : handleCircle(activeStepIndex, 5)">
                    <span class="text-2xl" [ngClass]="statusActiveStep5 ? 'text-white' : handleTextInCircle(activeStepIndex, 5)">
                        <ng-container *ngIf="!statusActiveStep5">5</ng-container>
                        <ng-container *ngIf="statusActiveStep5"><i class="fa-light fa-check"></i></ng-container>
                    </span>
                </div>
            </div>
            <div class="w-full hidden lg:block absolute right-50 hidden my-3" [ngClass]="statusActiveStep5 ? 'bg-purple-900' : handleLine(activeStepIndex, 5)" style="transform: translateY(-50%); height: 2px; top: 25%"></div>
            <div class="flex flex-column align-items-center">
                <h1 class="m-0 pt-2 font-semibold text-lg line-height-3" [ngClass]="statusActiveStep5 ? 'text-purple-900' : handleTextLabel(activeStepIndex, 5)">สำเนาเเจกจ่าย</h1>
            </div>
        </div>
    </li>
</ul> -->