import { PersonalInfoService } from 'src/app/services/api/per/personal-info.service';
import { OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { AppMenuitemComponent } from './app.menuitem.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {
  @ViewChild(AppMenuitemComponent) appMenuitemComponent!: AppMenuitemComponent;
  model: any[] = [];
  selectedPerson = false;
  destroy$ = new Subject();
  perMenus: any[] = [];

  constructor(private personalInfoService: PersonalInfoService) {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit() {
    this.personalInfoService.selectPersonalId
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value !== null) this.selectedPerson = true;
        else this.selectedPerson = false;

        this.model = this.getMenus();
      });

    this.model = this.getMenus();
  }

  getMenus() {
    const per = [
      {
        label: 'ประวัติหลัก',
        items: [
          {
            label: 'ยศ',
            routerLink: ['/per/primary-hitory/rank-info'],
          },
          {
            label: 'กำลังพล',
            routerLink: ['/per/primary-hitory/army-group-info'],
          },
          {
            label: 'เงินเดือน',
            routerLink: ['/per/primary-hitory/salary-info'],
          },
          {
            label: 'บำเหน็จ',
            routerLink: ['/per/primary-hitory/reward-info'],
          },
          {
            label: 'ตำแหน่ง',
            routerLink: ['/per/primary-hitory/position-info'],
          },
          {
            label: 'เครื่องราชอิสริยาภรณื',
            routerLink: ['/per/primary-hitory/royal-decoration-info'],
          },
          {
            label: 'เหล่าทัพ',
            routerLink: ['/per/primary-hitory/armed-force-info'],
          },
          {
            label: 'เหล่า',
            routerLink: ['/per/primary-hitory/corps-info'],
          },
          {
            label: 'คุณวุฒิการศึกษา',
            routerLink: ['/per/primary-hitory/education-info'],
          },
          {
            label: 'หลักสูตรทางทหาร/ฝึกอบรม',
            routerLink: ['/per/primary-hitory/military-course-info'],
          },
          {
            label: 'ข้อมูลการบรรจุ/เลื่อนฐานะ/บรรจุกลับ',
            routerLink: ['/per/primary-hitory/app-point-info'],
          },
        ],
      },
      {
        label: 'ประวัติเพิ่มเติม',
        items: [
          {
            label: 'การขึ้นทะเบียนกองประจำการ',
            routerLink: ['/per/additional-history/regular-register-info'],
          },
          {
            label: 'การออกจากราชการ',
            routerLink: ['/per/additional-history/retirement-info'],
          },
          {
            label: 'ความชอบพิเศษ',
            routerLink: ['/per/additional-history/good-ness-info'],
          },
          {
            label: 'ความชำนาญทางการทหาร',
            routerLink: ['/per/additional-history/military-expert-info'],
          },
          {
            label: 'ความชำนาญการใช้อาวุธ',
            routerLink: ['/per/additional-history/weapons-expert-info'],
          },
        ],
      },
    ];

    const menus = [
      {
        title: '',
        items: [
          {
            label: 'หน้าจอแดชบอร์ด',
            icon: 'fa-light fa-circle text-xs',
            routerLink: ['/dashboard'],
          },
          {
            label: 'ข้อมูลหลัก',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'หน่วยงาน',
                items: [
                  {
                    label: 'ประเภทหน่วยงาน',
                    routerLink: ['/mst/per/unit/unit-type'],
                  },
                  {
                    label: 'โครงสร้างหน่วยงาน',
                    routerLink: ['/mst/per/unit/unit-structure'],
                  },
                  {
                    label: 'ตำแหน่ง',
                    routerLink: ['/mst/per/unit/position'],
                  },
                  {
                    label: 'อัตรา',
                    routerLink: ['/mst/per/unit/position-rank'],
                  },
                  {
                    label: 'พรรค',
                    routerLink: ['/mst/per/unit/party'],
                  },
                  {
                    label: 'เหล่าทัพ',
                    routerLink: ['/mst/per/unit/armed-force'],
                  },
                  {
                    label: 'เหล่า',
                    routerLink: ['/mst/per/unit/corps'],
                  },
                  {
                    label: 'กำเนิด',
                    routerLink: ['/mst/per/unit/origin'],
                  },
                  {
                    label: 'ยศ',
                    routerLink: ['/mst/per/unit/rank'],
                  },
                  {
                    label: 'หน่วยงานภายนอก กห.',
                    routerLink: ['/mst/per/unit/unit-external'],
                  },
                  {
                    label: 'ตำแหน่งภายนอก กห.',
                    routerLink: ['/mst/per/unit/position-external'],
                  },
                  {
                    label: 'คำนำหน้า',
                    routerLink: ['/mst/per/unit/prefix'],
                  },
                ],
              },
              {
                label: 'การศึกษา',
                items: [
                  {
                    label: 'กฎกระทรวง',
                    routerLink: ['/mst/per/edu/ministerial-reg'],
                  },
                  {
                    label: 'สถานศึกษา',
                    routerLink: ['/mst/per/edu/institution'],
                  },
                  {
                    label: 'กลุ่มระดับการศึกษา',
                    routerLink: ['/mst/per/edu/education-level-group'],
                  },
                  {
                    label: 'ระดับการศึกษา',
                    routerLink: ['/mst/per/edu/education-level'],
                  },
                  {
                    label: 'คุณวุฒิการศึกษา',
                    routerLink: ['/mst/per/edu/qualification'],
                  },
                  {
                    label: 'สาขาวิชา',
                    routerLink: ['/mst/per/edu/field'],
                  },
                  {
                    label: 'วิชาเอก',
                    routerLink: ['/mst/per/edu/major'],
                  },
                  {
                    label: 'กลุ่มวิชา',
                    routerLink: ['/mst/per/edu/subject-group'],
                  },
                  {
                    label: 'เจ้าของหลักสูตรทางทหาร',
                    routerLink: ['/mst/per/edu/military-academy'],
                  },
                  {
                    label: 'กลุ่มหลักสูตรทางทหาร',
                    routerLink: ['/mst/per/edu/military-course-group'],
                  },
                  {
                    label: 'หลักสูตรทางทหารฝึกอบรม',
                    routerLink: ['/mst/per/edu/training-course'],
                  },
                  {
                    label: 'รุ่นเตรียมทหาร',
                    routerLink: ['/mst/per/edu/cadet-gen'],
                  },
                  {
                    label: 'หลักสูตรการใช้อาวุธ',
                    routerLink: ['/mst/per/edu/weapon-course'],
                  },
                  {
                    label: 'ชนิดอาวุธ',
                    routerLink: ['/mst/per/edu/weapon-type'],
                  },
                ],
              },
              {
                label: 'ภูมิประเทศ',
                items: [
                  {
                    label: 'ประเทศ',
                    routerLink: ['/mst/per/geography/country'],
                  },
                  {
                    label: 'จังหวัด/อำเภอ/ตำบล',
                    routerLink: ['/mst/per/geography/area'],
                  },
                ],
              },
              {
                label: 'เงินเดือน',
                items: [
                  {
                    label: 'เงินเดือนข้าราชการ',
                    routerLink: ['/mst/per/salary/gov-salary'],
                  },
                  {
                    label: 'เงินเพิ่มพิเศษ',
                    routerLink: ['/mst/per/salary/extra-salary'],
                  },
                  {
                    label: 'เงินประจำตำแหน่ง',
                    routerLink: ['/mst/per/salary/position-salary'],
                  },
                  {
                    label: 'เงินเดือนลูกจ้างประจำ',
                    routerLink: ['/mst/per/salary/full-time-salary'],
                  },
                  {
                    label: 'เงินเดือนพนักงานราชการ',
                    routerLink: ['/mst/per/salary/service-salary/service'],
                  },
                  {
                    label: 'ตำแหน่งทางวิชาการ',
                    routerLink: ['/mst/per/salary/academic-salary'],
                  },
                  {
                    label: 'การกำหนดปัจจัย',
                    routerLink: ['/mst/per/salary/salary-factor'],
                  },
                ],
              },
              {
                label: 'เงินประจำตำแหน่ง',
                routerLink: ['/mst/per/position-allowance'],
              },
              {
                label: 'เครื่องราชฯและเหรียญ',
                routerLink: ['/mst/per/royal-decoration'],
              },
              {
                label: 'รูปประจำตัว',
                routerLink: ['/mst/per/profile-pic'],
              },
              // {
              //   label: 'รายงาน',
              //   routerLink: ['/mst/per/report'],
              // },
              // {
              //   label: 'โครงการเกษียณอายุราชการก่อนกำหนด',
              //   routerLink: ['/mst/per/early-retire'],
              // },
              // {
              //   label: 'เกณฑ์การย้ายประเทศ',
              //   routerLink: ['/mst/per/emigration-rule'],
              // },
              {
                label: 'คำสั่ง',
                routerLink: ['/mst/per/order'],
                items: [
                  {
                    label: 'ประเภทคำสั่ง',
                    routerLink: ['/mst/per/order/transaction-type'],
                  },
                  {
                    label: 'หัวเรื่องคำสั่ง',
                    routerLink: ['/mst/per/order/subject'],
                  },
                  //     {
                  //       label: 'รับคำสั่ง',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                  //     {
                  //       label: 'ผู้มีอำนาจออกคำสั่ง',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                  //     {
                  //       label: 'สายการอนุมัติ',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                  //     {
                  //       label: 'ผู้ลงนามในสำเนา',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                  //     {
                  //       label: 'ผู้พิมพ์ทาน',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                  //     {
                  //       label: 'จัดการโควต้า',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                  //     {
                  //       label: 'รายชื่อ นขต.กห.',
                  //       routerLink: ['/mst/per/order/'],
                  //     },
                ],
              },
              // {
              //   label: 'ข้อมูลระบบ',
              //   routerLink: ['/mst/per/sys-info'],
              //   items: [
              //     {
              //       label: 'ค่ามาตรฐาน',
              //       routerLink: ['/mst/per/sys-info/standard-value'],
              //     },
              //     {
              //       label: 'ประเภทรายการ',
              //       routerLink: ['/mst/per/sys-info/items-type'],
              //     },
              //   ]
              // },
            ],
          },
          {
            label: 'งานทะเบียนประวัติ',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'ตรวจสอบข้อมูลประวัติการรับราชการ',
                routerLink: ['/per/personal-info'],
                items: this.selectedPerson ? per : null,
              },
              /* {
                 label: 'ประวัติรับราชการกำลังพล',
                 icon: '',
                 routerLink: ['/'],
             },
               {
                 label: 'ประวัติข้าราชการพลเรือนกลาโหม',
                 icon: '',
                 routerLink: ['/'],
               },
               {
                 label: 'ประวัติลูกจ้าง',
                 icon: '',
                 routerLink: ['/'],
               },
               {
                 label: 'ประวัติพนักงานราชการ',
                icon: '',
                 routerLink: ['/'],
               },*/
            ],
          },
          {
            label: 'งานข้อมูลการบรรจุ',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'นำเข้าข้อมูล',
                icon: '',
                routerLink: ['/asm/import'],
              },
              {
                label: 'ตรวจสอบคุณสมบัติ',
                icon: '',
                routerLink: ['/asm/verify'],
              },
              {
                label: 'ยกร่างคำสั่ง',
                icon: '',
                routerLink: ['/asm/draft'],
              },
              {
                label: 'อนุมัติคำสั่ง',
                icon: '',
                routerLink: ['/asm/approve'],
              },
              {
                label: 'สำเนาเเจกจ่าย',
                icon: '',
                routerLink: ['/asm/share'],
              },
            ],
          },
          {
            label: 'งานข้อมูลการปรับย้าย',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'นำเข้าข้อมูล',
                icon: '',
                routerLink: ['/trans/import'],
              },
              {
                label: 'ตรวจสอบคุณสมบัติ',
                icon: '',
                routerLink: ['/trans/verify'],
              },
              {
                label: 'ยกร่างคำสั่ง',
                icon: '',
                routerLink: ['/trans/draft'],
              },
              {
                label: 'อนุมัติคำสั่ง',
                icon: '',
                routerLink: ['/trans/approve'],
              },
              {
                label: 'สำเนาเเจกจ่าย',
                icon: '',
                routerLink: ['/trans/share'],
              },
            ],
          },
          {
            label: 'งานข้อมูลการออกจากราชการ',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'นำเข้าข้อมูล',
                icon: '',
                routerLink: ['/disc/import'],
              },
              {
                label: 'ตรวจสอบคุณสมบัติ',
                icon: '',
                routerLink: ['/disc/verify'],
              },
              {
                label: 'ยกร่างคำสั่ง',
                icon: '',
                routerLink: ['/disc/draft'],
              },
              {
                label: 'อนุมัติคำสั่ง',
                icon: '',
                routerLink: ['/disc/approve'],
              },
              {
                label: 'สำเนาเเจกจ่าย',
                icon: '',
                routerLink: ['/disc/share'],
              },
            ],
          },
          {
            label: 'งานข้อมูลช่วยราชการ',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'นำเข้าข้อมูล',
                icon: '',
                routerLink: ['/temp/import'],
              },
              {
                label: 'ตรวจสอบคุณสมบัติ',
                icon: '',
                routerLink: ['/temp/verify'],
              },
              {
                label: 'ยกร่างคำสั่ง',
                icon: '',
                routerLink: ['/temp/draft'],
              },
              {
                label: 'อนุมัติคำสั่ง',
                icon: '',
                routerLink: ['/temp/approve'],
              },
              {
                label: 'สำเนาเเจกจ่าย',
                icon: '',
                routerLink: ['/temp/share'],
              },
            ],
          },
          // {
          //   label: 'ระบบแสดงความคิดเห็น',
          //   icon: 'fa-light fa-circle text-xs',
          // },
          {
            label: 'จัดการสิทธิผู้ใช้งาน',
            icon: 'fa-light fa-circle text-xs',
            items: [
              {
                label: 'บัญชีผู้ใช้งาน',
                icon: '',
                routerLink: ['/sys/user-management'],
              },
              {
                label: 'สิทธิกลุ่มผู้ใช้งาน',
                icon: '',
                routerLink: ['/sys/group-permissions'],
              },
              {
                label: 'แอปพลิเคชัน',
                icon: '',
                routerLink: ['/sys/applications'],
              },
              {
                label: 'เมนูระบบ',
                icon: '',
                routerLink: ['/sys/system-menu'],
              },
              {
                label: 'สิทธิเมนู',
                icon: '',
                routerLink: ['/sys/permission-menu'],
              },
              // {
              //   label: 'ประชาสัมพันธ์',
              //   icon: '',
              //   routerLink: ['/sys/news'],
              // },
              // {
              //   label: 'ลงทะเบียนผู้ใช้',
              //   icon: '',
              //   routerLink: ['/sys/user-register'],
              // },
              {
                label: 'ข้อมูลประวัติการใช้งาน',
                icon: '',
                routerLink: ['/sys/history-log'],
              },
            ],
          },
        ],
      },
    ];

    return menus;
  }

  closeItem() {
    this.appMenuitemComponent.closeItem();
  }
}
