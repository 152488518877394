import { Component } from '@angular/core';

@Component({
  selector: 'app-calendar-th',
  templateUrl: './calendar-th.component.html',
  styleUrls: ['./calendar-th.component.scss']
})
export class CalendarThComponent {

}
