import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
})
export class AppFooterComponent {
  version: string = `${environment.appVersion}`;
  buildNo: string = `${environment.appBuildNo}`;

  constructor(public layoutService: LayoutService) {}

  get logo() {
    return this.layoutService.config.colorScheme === 'light' ? 'dark' : 'white';
  }
}
