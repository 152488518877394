import { Directive, Input, HostListener, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilsService } from './../services/utils.service'

@Directive({
  selector: '[appErrorMsg]'
})
export class ErrorMsgDirective implements OnInit, OnDestroy {
  constructor(private elRef: ElementRef,
    private control: NgControl,
    private utils: UtilsService,
  ) { }

  @Input('formControlName') formControlName!: string;
  errorSpanId: string = '';
  inputType: string = '';

  statusChangeSubscription!: Subscription;

  ngOnInit(): void {
    this.inputType = this.elRef.nativeElement.localName;
    this.errorSpanId = this.formControlName + new Date() + '-error-msg';
    if (this.control.statusChanges) {
      this.statusChangeSubscription = this.control.statusChanges.subscribe(
        (status) => {
          if (status == 'INVALID') {
            this.showError();
          } else {
            this.removeError();
          }
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription.unsubscribe();
  }

  @HostListener('blur', ["$event"])
  handleBlurEvent(event: any) {
    if (this.control.value == null || this.control.value == '') {
      if (this.control.errors) this.showError();
      else this.removeError();
    }
  }

  private showError() {
    this.removeError();
    const valErrors = this.control.errors;
    if (valErrors) {
      const errorMsg = this.getValidationMsg(this.inputType);
      const errSpan = '<small class="p-error block" id="' + this.errorSpanId + '">' + errorMsg + '</small>';
      this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
    }
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) errorElement.remove();
  }

  getValidationMsg(inputType: string) {
    const textInputTypes = ['input', 'p-chips', 'textarea'];
    return textInputTypes.includes(inputType.toLowerCase())
      ? this.utils.validatorMsg
      : 'กรุณาเลือก';
  }
}