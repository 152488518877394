export const environment = {
  apiMstUrl: 'https://modhrmisapi.kbtdevops.com/mst/api/',
  apiPerUrl: 'http://localhost:5218/api/',
  apiAuthUrl: 'https://modhrmisapi.kbtdevops.com/auth/api/',
  apiAsmUrl: 'https://modhrmisapi.kbtdevops.com/asm/api/',
  apiObjStorageUrl: 'https://modhrmisapi.kbtdevops.com/object-storage/api/',
  appVersion: '1.0.0',
  appBuildNo: '2024091901',
  rows: 50,
  rowsPerPageOptions: [20, 50, 100],
};
