import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { TreeNode } from 'primeng/api';
import { Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { OrgUnitService } from 'src/app/services/api/mst/per/unit/org-unit.service';
import { environment } from 'src/environments/environment';
import { DropdownService } from 'src/app/services/api/dropdown.service';
import { ModalPositionComponent } from '../modal-position/modal-position.component';
import { VwPersonalListService } from 'src/app/services/api/mst/view/vw-personal-list.service';
import { VwAdHocPositionService } from 'src/app/services/api/mst/view/vw-adhoc-position.service';

@Component({
  selector: 'app-modal-org-unit',
  templateUrl: './modal-org-unit.component.html',
  styleUrls: ['./modal-org-unit.component.scss'],
})
export class ModalOrgUnitComponent {
  mstOrgUnitParentID: any = null;
  mstAdHocPositionId: any = null;
  mstOrgUnitId: any = null;
  mstOrgUnitParentId: any = null;
  personalInfoID: any = null;
  isActive: boolean = false;
  treeSource: TreeNode[] = [];
  selected: any = null;
  private unsubscribe$: Subject<void> = new Subject<void>();

  personalInfoSource: any[] = []; // ข้อมูลผู้ดำรงตำแหน่ง
  adhocPositionSource: any[] = []; // ข้อมูลผู้ที่จองตำแหน่ง
  orgUnitPositionData: any = null; // ข้อมูลหน่วยงานและตำแหน่ง

  // dataForm: FormGroup;
  // positionRankForm: FormGroup;
  // positionUnitForm: FormGroup;
  // modalPosition: DynamicDialogRef | undefined;
  // positionList: any[] = []; // DDL ตำแหน่ง
  // positionRankList: any[] = []; // DDL อัตรา
  // positionTechnicalList: any[] = []; // DDL ตำแหน่งสายวิทยาการ
  // salaryPositionDetailList: any[] = []; // DDL เงินประจำตำแหน่ง
  // positionRemarkList: any[] = []; // DDL หมายเหตุตำแหน่ง
  // officerTypeList: any[] = []; // DDL ประเภทข้าราชการ
  // employeeJobGroupList: any[] = []; // DDL ประเภทงาน/ระดับ
  // orgUnitTypeList: any[] = []; // DDL ประเภทหน่วยงาน
  // armedForceList: any[] = []; // DDL เหล่าทัพ

  constructor(
    // private dialogService: DialogService,
    // private formBuilder: FormBuilder,
    // private dropdownService: DropdownService,
    public dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private utils: UtilsService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private orgUnitService: OrgUnitService,
    private vwPersonalListService: VwPersonalListService,
    private vwAdHocPositionService: VwAdHocPositionService
  ) {
    // this.dataForm = this.formBuilder.group({
    //   personnelGroupId: [], //ประเภทนายทหาร
    //   positionRankId: [], //อัตรา
    //   numberPosition: [], // เลขที่ตำแหน่ง
    //   namePosition: [], // ชื่อตำแหน่ง
    //   statusPosition1: [false], //สถานะตำแหน่งว่าง
    //   statusPosition2: [false], //สถานะตำแหน่งจอง
    //   statusPosition3: [false], //สถานะตำแหน่งทำหน้าที่
    // });
    // this.positionRankForm = this.formBuilder.group({
    //   form1: [],
    //   form2: [],
    //   form3: [],
    //   form4: [],
    //   form5: [],
    //   form6: [],
    //   form7: [],
    //   form8: [],
    //   form9: [],
    //   form10: [],
    //   form11: [],
    //   form12: [],
    //   form13: [],
    //   form14: [],
    //   form15: [],
    //   form16: [],
    //   form17: [],
    //   form18: [],
    //   form19: [],
    //   form20: [],
    //   form21: [],
    //   form22: [],
    //   form23: [],
    // });
    // this.positionUnitForm = this.formBuilder.group({
    //   form1: [],
    //   form2: [],
    //   form3: [],
    //   form4: [],
    //   form5: [],
    //   form6: [],
    //   form7: [],
    // });
  }

  ngOnInit(): void {
    let dialogData = this.dynamicDialogConfig.data;
    if (dialogData) {
      this.mstOrgUnitParentID = dialogData.mstOrgUnitParentID;
    }
    this.utils.spinnerShow();
    this.getTree()
      .pipe(
        tap((resp: any) => {
          // console.log(resp);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTree(): Observable<any> {
    return this.orgUnitService.getTreeById(this.mstOrgUnitParentID).pipe(
      switchMap((resp: any) => {
        if (resp?.data) {
          this.treeSource = resp.data;
        } else {
          this.treeSource = [];
        }
        return of(resp);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  getVwPersonalList(): Observable<any> {
    if (this.personalInfoID) {
      return this.vwPersonalListService
        .getVwPersonalList(this.personalInfoID)
        .pipe(
          switchMap((resp: any) => {
            if (resp?.data) {
              this.personalInfoSource = resp.data;
            } else {
              this.personalInfoSource = [];
            }
            return of(resp);
          }),
          takeUntil(this.unsubscribe$)
        );
    } else {
      this.personalInfoSource = [];
      return of(null);
    }
  }

  getVwAdHocPosition(): Observable<any> {
    if (this.mstAdHocPositionId) {
      return this.vwAdHocPositionService
        .getVwAdHocPosition(this.mstAdHocPositionId)
        .pipe(
          switchMap((resp: any) => {
            if (resp?.data && resp?.data.length > 0) {
              this.orgUnitPositionData = resp.data[0];
            } else {
              this.orgUnitPositionData = null;
            }
            return of(resp);
          }),
          takeUntil(this.unsubscribe$)
        );
    } else {
      this.orgUnitPositionData = null;
      return of(null);
    }
  }

  getDataSource() {
    this.utils.spinnerShow();
    this.getVwPersonalList()
      .pipe(
        switchMap(() => {
          return this.getVwAdHocPosition();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.utils.spinnerHide();
        },
        error: (err: any) => {
          this.utils.spinnerHide();
          this.httpErrorHandlerService.handleError(err);
        },
      });
  }

  nodeSelect(event: any) {
    console.log('nodeSelect', event?.node);
    let data: any = event?.node?.data;
    if (data) {
      this.isActive = data.isActive;
      this.mstAdHocPositionId = data.mstAdHocPositionId;
      this.mstOrgUnitId = data.mstOrgUnitId;
      this.mstOrgUnitParentId = data.mstOrgUnitParentId;
      this.personalInfoID = data.personalInfoID;
      this.getDataSource();
    }
  }

  nodeUnselect(event: any) {
    // console.log('nodeUnselect', event?.node);
  }

  nodeExpand(event: any) {
    // console.log('nodeExpand', event?.node);
  }

  nodeCollapse(event: any) {
    // console.log('nodeCollapse', event?.node);
  }

  handleSelectePosition(): boolean {
    // if (
    //   !this.orgUnitPositionData ||
    //   this.orgUnitPositionData?.isActive === false ||
    //   this.orgUnitPositionData?.personalInfoId
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }

    return false;
  }

  choose() {
    // let personalInfoData: any = null;
    // if (this.personalInfoSource.length > 0) {
    //   personalInfoData = this.personalInfoSource[0];
    // }
    // console.log('personalInfoData:', personalInfoData);
    // let adHocPositionData: any = null;
    // if (this.adhocPositionSource.length > 0) {
    //   adHocPositionData = this.adhocPositionSource[0];
    // }
    // console.log('adHocPositionDat:', adHocPositionData);
    // let obj: Object = {
    //   personalInfoData: personalInfoData,
    //   adHocPositionData: adHocPositionData,
    // };
    // console.log(obj);
    // console.log(this.orgUnitPositionData);
    this.dynamicDialogRef.close(this.orgUnitPositionData);
  }

  close() {
    this.dynamicDialogRef.close(null);
  }

  // getDropdown(): Observable<any> {
  //   return this.dropdownService.getMstPosition().pipe(
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.positionList = resp.data;
  //       }
  //       return this.dropdownService.getMstPositionRank();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.positionRankList = resp.data;
  //       }
  //       return this.dropdownService.getMstPositionTechnical();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.positionTechnicalList = resp.data;
  //       }
  //       return this.dropdownService.getMstSalaryPositionDetail();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.salaryPositionDetailList = resp.data;
  //       }
  //       return this.dropdownService.getMstPositionRemark();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.positionRemarkList = resp.data;
  //       }
  //       return this.dropdownService.getMstOfficerType();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.officerTypeList = resp.data;
  //       }
  //       return this.dropdownService.getMstEmployeeJobGroup();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.employeeJobGroupList = resp.data;
  //       }
  //       return this.dropdownService.getMstOrgUnitType();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.orgUnitTypeList = resp.data;
  //       }
  //       return this.dropdownService.getMstArmedForce();
  //     }),
  //     switchMap((resp: any) => {
  //       if (resp?.data) {
  //         this.armedForceList = resp.data;
  //       }
  //       return of(null);
  //     }),
  //     takeUntil(this.unsubscribe$)
  //   );
  // }

  // searchAction() {
  //   console.log(this.dataForm.value);
  // }

  // cancel() {
  //   this.positionRankList = [];
  //   this.dataForm.reset();
  // }

  // openModalPosition(id: any) {
  //   console.log(id);
  //   this.modalPosition = this.dialogService.open(ModalPositionComponent, {
  //     header: 'ข้อมูลตำแหน่ง',
  //     width: '70%',
  //     height: '70%',
  //     contentStyle: { overflow: 'auto' },
  //     baseZIndex: 10000,
  //     closable: true,
  //     closeOnEscape: false,
  //     styleClass: '',
  //     appendTo: 'body',
  //     data: {
  //       id: id,
  //     },
  //   });
  //   this.modalPosition.onClose.subscribe((resp) => {
  //     console.log(resp);
  //   });
  // }

  // expandAll() {
  //   this.treeSource.forEach((node) => {
  //     this.expandRecursive(node, true);
  //   });
  // }

  // collapseAll() {
  //   this.treeSource.forEach((node) => {
  //     this.expandRecursive(node, false);
  //   });
  // }

  // private expandRecursive(node: TreeNode, isExpand: boolean) {
  //   node.expanded = isExpand;
  //   if (node.children) {
  //     node.children.forEach((childNode) => {
  //       this.expandRecursive(childNode, isExpand);
  //     });
  //   }
  // }

  // resetForm() {
  //   this.positionRankForm.reset();
  //   this.positionUnitForm.reset();
  // }
}
