import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppLayoutComponent } from './app.layout.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppSearchComponent } from './app.search.component';
import { AppFooterComponent } from './app.footer.component';
import { PrimengModule } from '../modules/shared/primeng.module';
import { SharedModule } from '../modules/shared/shared.module';

@NgModule({
  declarations: [
    AppLayoutComponent,
    AppBreadcrumbComponent,
    AppSidebarComponent,
    AppTopbarComponent,
    AppRightMenuComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppSearchComponent,
    AppFooterComponent,
  ],
  imports: [
    FormsModule,
    RouterModule,
    PrimengModule,
    SharedModule,
  ],
  exports: [
    AppLayoutComponent,
    AppBreadcrumbComponent,
    AppSidebarComponent,
    AppTopbarComponent,
    AppRightMenuComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppSearchComponent,
    AppFooterComponent,
  ],
})
export class AppLayoutModule {}
