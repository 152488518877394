// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { SharedRoutingModule } from './shared-routing.module';
import { CoresModule } from 'src/app/core/cores.module';
import { PrimengModule } from './primeng.module';
import { AppConfigModule } from 'src/app/layout/config/app.config.module';

// Components
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { StepBarComponent } from './step-bar/step-bar.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PreviewPdfDialogComponent } from './preview-pdf-dialog/preview-pdf-dialog.component';
import { ModalOrgUnitComponent } from './modal-org-unit/modal-org-unit.component';
import { ModalPositionComponent } from './modal-position/modal-position.component';
import { ModalHistoryImportFileComponent } from './modal-history-import-file/modal-history-import-file.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    StepBarComponent,
    PersonDetailsComponent,
    PreviewPdfDialogComponent,
    ModalOrgUnitComponent,
    ModalPositionComponent,
    ModalHistoryImportFileComponent,
  ],
  imports: [
    CoresModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedRoutingModule,
    AppConfigModule,
    PrimengModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppConfigModule,
    HttpClientModule,
    ConfirmDialogComponent,
    StepBarComponent,
    PersonDetailsComponent,
    PreviewPdfDialogComponent,
    ModalOrgUnitComponent,
  ],
})
export class SharedModule {}
