<p-table [value]="dataMain" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true" scrollHeight="450px"
        [showCurrentPageReport]="true" [paginator]="true" [first]="first" [sortOrder]="sortOrder" 
        [sortField]="sortField" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" 
        [totalRecords]="dataMain.length" currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
        <ng-template pTemplate="header">
            <tr class="white-space-nowrap">
                <th>ชื่อไฟล์</th>
                <th class="text-right">นำเข้าทั้งหมด (คน)</th>
                <th class="text-right">นำเข้าสำเร็จ (คน)</th>
                <th class="text-right">นำเข้าไม่สำเร็จ (คน)</th>
                <th>วันที่นำเข้า</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr class="white-space-nowrap cursor-pointer hover:bg-gray-400" (click)="getDetail(data?.impTransactionId)" >
                <td>{{data?.fileName}}</td>
                <td class="text-right">{{data?.totalRecord}}</td>
                <td class="text-right">{{data?.totalSuccess}}</td>
                <td class="text-right">{{data?.totalError}}</td>
                <td>{{data?.createdDate | formatShortDateTimeThai}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <ng-container *ngIf="dataMain.length <= 0">
                <tr>
                    <td colspan="5" class="text-center bg-white">
                        ไม่มีข้อมูล
                    </td>
                </tr>
            </ng-container>
        </ng-template>
</p-table>

<h4 class="text-purple-900 mt-5 mb-3">รายละเอียดการนำเข้า</h4>
<p-table [value]="dataSourceDetail" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true" scrollHeight="450px"
        [showCurrentPageReport]="true" [paginator]="true" [first]="first" [sortOrder]="sortOrder" 
        [sortField]="sortField" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" 
        [totalRecords]="dataSourceDetail.length" currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
        <ng-template pTemplate="header">
            <tr class="white-space-nowrap">
                <th>หมายเลขบัตรประชาชน</th>
                <th>เลขประจำตัวทหาร</th>
                <th>ชื่อ-นามสกุล</th>
                <th>ตำเเหน่ง</th>
                <th>วันที่นำเข้า</th>
                <th pFrozenColumn alignFrozen="right" [frozen]="true" [width]="200">สถานะการนำเข้า</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr class="white-space-nowrap">
                <td>{{data.citizenId}}</td>
                <td>{{data.militaryId}}</td>
                <td>{{data.mstMilitaryRankNameExcel}} {{data.firstName}} {{data.lastName}}</td>
                <td>{{data.positionName}}</td>
                <td>{{data.createdDate | formatShortDateThai}}</td>
                <td pFrozenColumn alignFrozen="right" [frozen]="true">
                    <div class="bg-green-100 text-green-600 font-bold p-2 border-round w-auto inline" *ngIf="data.isError == false" [innerHTML]="data?.exception | newLine"></div>
                    <div class="bg-red-100 text-red-600 font-bold p-2 border-round w-auto" *ngIf="data.isError == true" [innerHTML]="data?.exception | newLine"></div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <ng-container *ngIf="dataSourceDetail.length <= 0">
                <tr>
                    <td colspan="6" class="text-center bg-white">
                        ไม่มีข้อมูล
                    </td>
                </tr>
            </ng-container>
        </ng-template>
</p-table>

<div class="flex justify-content-center gap-3 my-3">
    <p-button label="ปิด" icon="fa-light fa-x" iconPos="left" [outlined]="true" severity="danger" styleClass="w-10rem" (onClick)="close(false)"></p-button>
</div>