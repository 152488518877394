import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VwPersonalListService {
  constructor(private httpClientService: HttpClientService) {}

  getVwPersonalList(personalInfoId: any) {
    const URL: string = environment.apiMstUrl + `VwPersonalList/${personalInfoId}`;
    return this.httpClientService.get(URL, true);
  }
}
