import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PersonalInfoResponse } from '../../generated-api/model/personalInfoResponse';

@Injectable({
  providedIn: 'root',
})
export class PersonalInfoService {
  private baseApiUrl = `${environment.apiPerUrl}PersonalInfo`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  selectPersonalId = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient) {}

  search(
    id?: string | undefined,
    mstTitle?: string | undefined,
    mstOrgUnitId?: string | undefined,
    citizenId?: string | undefined,
    firstName?: string | undefined,
    lastName?: string | undefined,
    usePaging: boolean = true,
    pageIndex: number = 0,
    pageSize: number = 10
  ): Observable<PersonalInfoResponse> {
    let str = '?';

    if (id) str += `id=${id}`;
    if (mstTitle) str += `mstTitle=${mstTitle}`;
    if (mstOrgUnitId) str += `mstOrgUnitId=${mstOrgUnitId}`;
    if (firstName) str += `firstName=${firstName}`;
    if (citizenId) str += `citizenId=${citizenId}`;
    if (lastName) str += `lastName=${lastName}`;

    str += `&usePaging=${usePaging}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.httpClient.get<PersonalInfoResponse>(
      `${this.baseApiUrl}${str}`,
      {
        headers: this.headers,
      }
    );
  }
}
