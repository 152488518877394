import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { CalendarThComponent } from './calendar-th/calendar-th.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AppConfigModule } from 'src/app/layout/config/app.config.module';
import { HttpClientModule } from '@angular/common/http';
import { StepBarComponent } from './step-bar/step-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from './primeng.module';
import { PersonDetailsComponent } from './person-details/person-details.component';

@NgModule({
  declarations: [
    AlertDialogComponent,
    CalendarThComponent,
    ConfirmDialogComponent,
    StepBarComponent,
    PersonDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedRoutingModule,
    AppConfigModule,
    PrimengModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertDialogComponent,
    CalendarThComponent,
    ConfirmDialogComponent,
    StepBarComponent,
    PersonDetailsComponent,
    AppConfigModule,
    HttpClientModule,
  ],
})
export class SharedModule {}
